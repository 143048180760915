/** @format */
@import "~antd/dist/antd.css";

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}

.capitalizeText {
  text-transform: capitalize;
}

.lowercaseText {
  text-transform: lowercase;
}

.testBorder {
  border: 2px solid black;
}

/* #Login-Page */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background: #d9edff; */
  background: #001628;
}

.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}
.login-button {
  background-color: #011628; /* Default background color */
  color: #fff; /* Default text color */
}

.login-button:hover {
  background-color:  #011628a6; /* Background color on hover */
  color: #fff; /* Text color on hover */
}
#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 30px 60px;
}

#login-form p {
  margin-bottom: 30px;
}

#login-form p.form-title {
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}

#login-form .ant-form-item-label>label.ant-form-item-required::before {
  display: none;
}

.ant-form-item-control-input-content {
  text-align: left;
}

#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}

#login-form_userName {
  height: 48px;
}

#login-form_subDomain {
  height: 48px;
}

#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}

.login-form-button {
  width: 100%;
}

.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: white;
}

.illustration-wrapper img {
  display: block;
  width: 100%;
}

@media screen and (max-width:800px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }

  .illustration-wrapper {
    display:none;
    max-width: 100%;
    min-height: auto;
  }

  #login-form {
    max-width: 100%;
  }
}

/* SuperAdmin */
/* .logo {
  height: 32px;
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
} */
/* Dashboard Page */
.header {
  background: white;
}

.pages-mainframe-navbar-logo-container {
  float: left;
  width: 200px;
  height: 63px;
  background: #001628;
}

.pages-mainframe-navbar-logo-image {
  width: 150px;
  height: 40px;
  margin: 0px 25px 0px 25px;
  cursor: pointer
}

.ant-layout-header {
  padding: 0px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

#pages-mainframe-navbar-small-logo-visibility {
  display: none;
}

/* ##########  media queries  #####*/
@media screen and (max-width: 600px) {
  #pages-mainframe-navbar-big-logo-visibility {
    display: none;
  }

  #pages-mainframe-navbar-small-logo-visibility {
    display: block;
  }

  .pages-mainframe-navbar-logo-container {
    width: 63px;
    border-radius: 0px 40px 40px 0px;
  }

  .pages-mainframe-navbar-logo-image {
    width: 45px;
    height: 45px;
    margin: 8px 0px 0px 8px;
  }
}

/* Drawer */

.ant-drawer-header {
  background-color: #f5f5f5;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.global-spinner-container {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(146, 146, 146, 0.418);
  justify-content: center;
  align-items: center;
}

.global-spinner {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 1000;
}

.user-table-disabled-row {
  /* background-color: rgb(223, 144, 144); */
  pointer-events: none;
}

.profile-Label-Text {
  font-weight: bolder;
}

.ticket-analysis-cards {
  background: #e6f4ff;
  margin-bottom: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5%;
  width: 100%;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-analysis-cards:hover {
  background: #fff;
  /* filter: drop-shadow(0px 4px 4px #188cf98f); */
}

.textOverflowForHomeTabs {
  white-space: wrap;
  overflow: scroll;
  /* text-overflow: ellipsis; */
}

.site-drawer-render-in-current-wrapper {
  position: relative;

  overflow: hidden;
}

.homePageAnalysis>.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item {
  padding: 0px 8px;
  /* margin-bottom: -10px; */
  margin-top: 8px;
}

.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-horizontal>.ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.ant-menu-vertical>.ant-menu-item {
  height: 50px;
}

.homePageAnalysis>.ant-menu-vertical>.ant-menu-item {
  height: 60px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  text-transform: capitalize;




}

.row-dragging td {
  padding: 10px 30px;


}

.row-dragging .drag-visible {
  visibility: visible;

}

.example-card .ant-card-head-title {
  padding: 9px;
}

.ant-divider-horizontal.ant-divider-with-text-left::after {

  top: 15%;
}


.formItemBottomMargin {
  margin-bottom: 0px;
}

.ant-picker-footer-extra {
  padding-left: 0px !important;
}

 .timePickerFooter {
  display: grid;
  grid-auto-flow: column;
}

.timePickerFooter div {
  padding-left: 20px;
  text-align: left;
}


.subRuleSLA {
  margin-bottom: 10px;
  width: 100%;
  background: #EAEDF0;
}

.logMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

.custom-button-orange {
  background-color: orange;  /* Set your desired background color */
  border-color: orange;  /* Set the border color (optional) */
  color: white;  /* Set the text color (optional) */
}

.custom-button-orange:hover {
  background-color: rgba(255, 166, 0, 0.884);  /* Set the background color for hover */
  border-color: rgba(255, 166, 0, 0.884);  /* Set the border color for hover (optional) */
  color: white;  /* Set the text color for hover (optional) */
}